export const settings = {
  apiURL: "https://tp-java-backend-ecommerce-5d0896ac6865.herokuapp.com",
  siteName: "Gaming Pro Market",
  siteUrl: "gamingpromarket.com",
  email: "info@gamingpromarket.com",
  phone1: "+1 (201)-623-3897",
  phone2: "+1 (201)-623-3897",
  whatsapp: "+1 (201)-623-3897",
  address: "431 5th Ave, New York USA",
  mapUrl: "https://goo.gl/maps/pGV851yYSA79FWRk8",
  mapEmbedUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.494775832329!2d-73.98440588441511!3d40.751141279327605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25900bce184a3%3A0x92beda759a7eb12a!2s431%205th%20Ave%2C%20New%20York%2C%20NY%2010016%2C%20USA!5e0!3m2!1sen!2str!4v1670489235663!5m2!1sen!2str",
};
